import React from "react";
import styles from "./About.module.css";
import Header2 from "../Header2/Header2";
import Footer from "../Footer/Footer";
import Image1 from "../../Images/team1.png";
import Image2 from "../../Images/team2.png";
const About = () => {
  return (
    <div>
      <Header2 />
      <div className={styles.AboutMain}>
        <div className={styles.AboutBgMain}>
          <div className={styles.mainHeading}>
            We are a values driven wealth planning firm that puts your heart at
            the center of the plan. Money is simply a tool to steward towards
            goals and to be generous with. Life is about more than numbers, its
            about people.
          </div>
        </div>

        <div className={styles.TeamMainHeading}>Our Team</div>
        <div className={styles.teamMain}>
          <div className={styles.teamCont}>
            <div className={styles.team1main}>
              <div className={styles.teamImgMain}>
                <img src={Image1} alt="" className={styles.teamImg} />
              </div>
              <div className={styles.teamheading1}>Founder</div>
              <div className={styles.teamheading2}>Garrett Daniel Bio</div>
              <div className={styles.teamtext}>
                Garrett is a Nashville native growing up in Franklin, and then
                attending the University of Tennessee in Knoxville. After
                finishing his degrees, he moved back to Nashville to be closer
                to family and met his wife Kaitie – who is also a Nashville
                native.
              </div>
              <br />
              <div className={styles.teamtext}>
                They have two beautiful girls, Emmie Ruth and Collins and live
                in the Crieve Hall area.
              </div>
              <br />
              <div className={styles.teamtext}>
                Garrett and Kaitie are very involved with their church Midtown
                Fellowship, love hosting friends, and being around their
                community. They both are big sports fans and enjoy spending time
                outdoors with their girls. Garrett has been an advisor for 8
                years now and has grown his business with a intentional effort
                from the beginning. He has chosen to work with a small number of
                households to create room for deeper relationships and more
                on-going financial planning. People’s stories are at the hearts
                of their financial plans, and it’s the part Garrett loves the
                most – getting to know people and creating a safe place to be
                heard and understood.
              </div>
            </div>
            <div className={styles.team2main}>
              <div className={styles.teamImgMain}>
                <img src={Image2} alt="" className={styles.teamImg} />
              </div>
              <div className={styles.teamheading1}>Client Service Manager</div>
              <div className={styles.teamheading2}>Janessa Shearman Bio</div>
              <div className={styles.teamtext}>
                Janessa enlisted in the United States Army as a Fire Control
                Specialist in 2019. She had the opportunity to work with
                automated computer-based systems used in all U.S. Army echelons
                across multiple weapon systems and formations to facilitate the
                delivery and integration of joint fires in support of combat
                operations. Having a military background, she is knowledgeable
                in computers and communication systems
              </div>
              <br />
              <div className={styles.teamtext}>
                Janessa has been a stay-at-home mother for the past two years.
                She enjoys spending quality time with her two boys and her
                husband. When Janessa isn’t spending time with her family, you
                can find her in the kitchen baking while listening to her
                favorite music playlist or outside working on her family’s
                two-acre homestead
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
