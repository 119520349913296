import React, { useState } from "react";
import styles from "./Footer.module.css";
import Logo from "../../Images/logo2.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Modal.css";
import axios from "axios";
import Swal from "sweetalert2";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div className={styles.modalHeading}>Contact us</div>
        <div className={styles.modalinput}>
          <input
            type="text"
            placeholder="Your Full Name"
            className={styles.inputemail}
            name="firstName"
            value={props?.formData.firstName}
            onChange={props?.handleChange}
          />
        </div>
        <div className={styles.modalinput}>
          <input
            type="email"
            placeholder="Email Address"
            className={styles.inputemail}
            name="email"
            value={props?.formData.email}
            onChange={props?.handleChange}
            required
          />
        </div>
        <div className={styles.modalinput}>
          <textarea
            id=""
            type="text"
            placeholder="Message"
            className={styles.inputmessage}
            name="message"
            value={props?.formData.message}
            onChange={props?.handleChange}
          ></textarea>
          {/* <input type="text" placeholder='Message' className={styles.inputemail} /> */}
        </div>

        <button className={styles.modalButton} onClick={props?.handleSubmit}>
          {props?.formStatus ? props?.formStatus : "Submit"}
        </button>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}
const Footer = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    message: "",
  });
  const [formStatus, setFormStatus] = useState("");
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus("Sending...");

    try {
      // Send form data to your Express API
      const response = await axios.post(
        "https://solomonwm.com/api/api/send-email",
        formData
      );

      if (response.status === 200) {
        setFormStatus("");
        Swal.fire({
          text: "Email sent successfully!",
          icon: "success",
        });
      } else {
        Swal.fire({
          text: "Failed to send email. Please try again later.",
          icon: "error",
        });
        setFormStatus("");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      Swal.fire({
        text: "Error occurred while sending email.",
        icon: "error",
      });
      setFormStatus("");
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className={styles.footerMain}>
      <div className={styles.footerContainer}>
        <div className={styles.footerLeftMain}>
          <div className={styles.footerLeftLogo}>
            <img src={Logo} alt="" className={styles.logo} />
          </div>
          <div className={styles.FooterLeftText}>
            Securities and advisory services offered through LPL Financial, a
            Registered Investment Advisor, Member <br />
            <a href="https://www.finra.org/" target="_blank">
              FINRA/
            </a>
            <a href="https://www.sipc.org/" target="_blank">
              SIPC
            </a>
            .&nbsp;The LPL Financial registered representative(s) associated
            with this website may discuss and/or transact business only with
            residents of the states in which they are properly registered or
            licensed. No offers may be made or accepted from any resident of any
            other state.
            {/* LPL Financial Form CRS
            <br />
            Check the background of your financial professional on FINRA's
            BrokerCheck.
            <br />
            The content is developed from sources believed to be providing
            accurate information. The information in this material is not
            intended as tax or legal advice. <br /> Please consult legal or tax
            professionals for specific information regarding your individual
            situation. <br /> */}
            {/* <span>Read more</span>  */}
          </div>
        </div>
        <div className={styles.footerRightMain}>
          <div className={styles.footerLinks}>
            <Link to="/about" className={styles.abtLink} onClick={scrollToTop}>
              <div className={styles.footerLinkText}>About</div>
            </Link>
            <Link
              className={styles.abtLink}
              to="#"
              onClick={() =>
                document
                  .getElementById("action2")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              <div className={styles.footerLinkText}>Planner</div>
            </Link>

            <div
              className={styles.footerLinkText}
              onClick={() => setModalShow(true)}
            >
              Contact us
            </div>
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              formData={formData}
              handleChange={handleChange}
              formStatus={formStatus}
              handleSubmit={handleSubmit}
            />
          </div>
          <div className={styles.footerContactMain}>
            <div className={styles.footerContactHeading}>Contact Us</div>
            <div className={styles.footerContactText}>615.237.8556</div>
            <div className={styles.footerContactText}>
              gdaniel@solomonwm.com
            </div>
          </div>
          <div className={styles.footerLocationMain}>
            <div className={styles.footerContactHeading}>Location</div>
            <div className={styles.footerContactText}>8 Cadillac Dr</div>
            <div className={styles.footerContactText}>Suite 300</div>
            <div className={styles.footerContactText}>Brentwood, TN 37027</div>
          </div>
        </div>
      </div>
      <div className={styles.footerCopyRight}>
        © 2021 — Copyright <br />
        All Rights reserved
      </div>
    </div>
  );
};

export default Footer;
